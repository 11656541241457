import { logger } from "core/modules/logging/Logger"

type OkResult = [Blob, null]
type ErrorResult = [null, unknown]

const log = new logger("PDF")

export const printPdf = async (targetUrl: string): Promise<OkResult | ErrorResult> => {
  try {
    log.info("Printing to PDF", targetUrl)

    const url = new URL(import.meta.env.VITE_PDF_PRINTER_URL)
    url.searchParams.append("url", targetUrl)

    const response = await fetch(url.toString())
    if (!response.ok || response.status >= 400) {
      log.warning("Could not print PDF", { status: response.status, statusText: response.statusText })

      return [null, new Error(`Could not print PDF: ${response.status} ${response.statusText}`)]
    }

    const blob = await response.blob()

    return [blob, null]
  } catch (error) {
    log.error("Could not print PDF", error)

    return [null, error]
  }
}
