import type { Localization } from "core/modules/localization/Localization"
import type { TranslatedString } from "core/modules/state/model/Model"

interface Answer {
  category?: TranslatedString
  options?: Array<{ points?: number; title?: TranslatedString }>
  question_key?: string
  question_meta?: Record<string, any>
}

export const getAnswersAverageScore = <T extends Answer>(answers: T[]) => {
  // @TODO: This may create an invalid result if 0 points is a valid result. We need to make sure
  // not to provide answers that should not be counted towards the average
  const points = answers?.map(getAnswerPoints).filter(Boolean)
  // This isn't quite right as the max value is capped to 5 points. We might need to take max
  // value as a parameter and convert all results to a range from 1 to max value
  const totalPoints = points?.reduce((total, points) => total + Math.min(5, points), 0)

  return totalPoints / (points.length || 1)
}

// Use fixed value for now, the data isn't available from server at this time
export const getCategoryMaxPoints = <T extends Answer>(answers: T[] | undefined, categoryPrefix: string) =>
  (answers?.length ?? 0) > 0 ? 5 : undefined

export const getCategoryAverageScore = <T extends Answer>(answers: T[] | undefined, categoryPrefix: string) => {
  if (!answers) return undefined

  answers = answersByCategory(answers, categoryPrefix)
  return getAnswersAverageScore(answers)
}

export const answersByCategory = <T extends Answer>(answers: T[] | undefined, categoryPrefix: string) =>
  answers?.filter(a => a.question_key?.startsWith(categoryPrefix + "_")) ?? []

export const getAnswerPoints = <T extends Answer>(answer?: T) =>
  answer?.options
    ?.map(option => option?.points ?? 0)
    .reduce((total, points) => total + points, answer.question_key === "sr_practices" ? 1 : 0) ?? 0

export const getAnswerTitle = <T extends Answer>(answer: T, localization: Localization) =>
  answer?.options
    ?.map(option => option.title && localization.txt(option.title!))
    .filter(Boolean)
    .join(", ")

export const getAnswerMinPoints = (answer: Answer) => {
  if (["wc_goal", "wc_lifestyle_changes"].includes(answer.question_key!)) return -2

  return 1
}

export const getAnswerMaxPoints = (answer: Answer) => {
  if (answer.question_key === "sr_practices") {
    // This is a checkbox question with 7 options
    return 7
  }
  if (["wc_goal", "wc_lifestyle_changes"].includes(answer.question_key!)) return 2
  if (
    [
      "ls_ladder",
      "wp_ladder",
      "gm_change_need",
      "gm_change_motivation",
      "gm_change_capability",
      "gm_change_support"
    ].includes(answer.question_key!)
  ) {
    return 10
  }

  // The default answer max score is 5 points
  return 5
}

export const getScoreColorSetpointsForAnswer = (answer: Answer) => {
  if (getAnswerMinPoints(answer) === -2 && getAnswerMaxPoints(answer) === 2) {
    return {
      darkGreen: 2,
      lightGreen: 1,
      yellow: 0,
      orange: -1,
      red: -2
    }
  }

  if (getAnswerMaxPoints(answer) === 10) return { darkGreen: 9, lightGreen: 8, yellow: 6, orange: 4, red: 1 }
  else if (getAnswerMaxPoints(answer) === 7) return { darkGreen: 6, lightGreen: 4, yellow: 3, orange: 2, red: 1 }

  return { darkGreen: 5, lightGreen: 4, yellow: 3, orange: 2, red: 1 }
}
